<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>诊单管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/medicalOrderManage' }">诊单列表</el-breadcrumb-item>
      <el-breadcrumb-item>诊单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <h2 style="text-align: left; padding: 0px 5px; color: rgb(1, 131, 30); font-size: 30px;">
      患者诊单
    </h2>
    <main class="el-main">
      <p style="margin-top: 0;text-align: left;">
        诊单状态:
        <span v-if="State == 0">未填写</span>
        <span v-if="State == 1">提交</span>
        <span v-if="State == 2">审核</span>
        <span v-if="State == 3">打回</span>
        <span v-if="State == 6">已下发</span>
        <span v-if="State == 9">删除</span>
      </p>
      <table border="1" cellspacing="0" cellpadding="0" id="tblA" class="table-condensed">
        <tbody>
          <tr>
            <td colspan="10" align="center" class="td-title">客户信息</td>
          </tr>
          <tr>
            <td width="120px" class="td-title">编号:</td>
            <td width="200px" align="left">{{ KID }}</td>
            <td class="td-title">客户姓名:</td>
            <td width="120px">{{ custmsg.Cust_Name }}</td>
            <td width="80px" class="td-title">性别:</td>
            <td width="80px">{{ custmsg.Cust_Sex == 0 ? "男" : "女" }}</td>
            <td class="td-title">年龄:</td>
            <td width="100px">{{ custmsg.Cust_Age }}</td>
            <td class="td-title">联系方式:</td>
            <td width="180px">{{ custmsg.Tel1 }}</td>
          </tr>
          <tr>
            <td class="td-title">职业:</td>
            <td>{{ custmsg.Cust_Job }}</td>
            <td class="td-title">出生日期:</td>
            <td>{{ Cust_Birthday }}</td>
            <td class="td-title">身高:</td>
            <td>{{ custmsg.Cust_Height }}</td>
            <td class="td-title">体重:</td>
            <td>{{ custmsg.Cust_Weight }}</td>
            <td class="td-title">文化程度:</td>
            <td>{{ custmsg.Cust_Schools }}</td>
          </tr>
          <tr>
            <td align="left" class="td-title">客户编号:</td>
            <td align="left">{{ custmsg.Cust_ID }}</td>
            <td align="left" class="td-title">家庭住址:</td>
            <td colspan="7">
              {{ custmsg.Provice + custmsg.City + custmsg.Country + custmsg.Address }}
            </td>
          </tr>
          <tr>
            <td class="td-title">主治医生:</td>
            <td>{{ custmsg.doc_name }}</td>
            <td class="td-title">责任客服:</td>
            <td>{{ custmsg.Cust_Servicer }}</td>
            <td class="td-title">诊单状态:</td>
            <td colspan="2">
              <template>
                <span v-if="State == 0">未填写</span>
                <span v-if="State == 1">提交</span>
                <span v-if="State == 2">审核</span>
                <span v-if="State == 3">打回</span>
                <span v-if="State == 6">已下发</span>
                <span v-if="State == 9">删除</span>
              </template>
            </td>
            <td class="td-title">创建时间:</td>
            <td colspan="3">{{ custmsg.Create_Date }}</td>
          </tr>
          <tr>
            <td colspan="10" align="center" class="td-title">客户症状体征</td>
          </tr>
          <tr>
            <td class="td-title">症状和体征</td>
            <td colspan="9">
              <p v-for="(item, index) in list" :key="index">
                {{ item.KFiel_ShowCaption }}：{{ item.KFValues }}<br />
              </p>
            </td>
          </tr>
          <!-- <tr>
                        <td class="td-title">已改善</td>
                        <td colspan="9"></td>
                    </tr>
                    <tr>
                        <td class="td-title">未改善</td>
                        <td colspan="9"></td>
                    </tr>
                    <tr>
                        <td class="td-title">现在没好疾病</td>
                        <td id="Did10" colspan="9"></td>
                    </tr>
                    <tr>
                        <td class="td-title">备注说明:</td>
                        <td colspan="9">aaa</td>
                    </tr> -->
        </tbody>
      </table>
      <br />
      <h2 style="margin-top: 0;text-align: left;">治疗方案：</h2>
      <table border="1" cellspacing="0" cellpadding="0" id="tblA" class="table-condensed">
        <tbody>
          <tr>
            <td colspan="6" align="center" class="td-title">方案内容</td>
          </tr>
          <tr>
            <td class="td-title">运动饮食方案</td>
            <td colspan="5">{{ fa.YSTZFA }}</td>
          </tr>
          <tr>
            <td class="td-title">临床诊断</td>
            <td colspan="5">{{ fa.YDFA }}</td>
          </tr>
          <tr>
            <td class="td-title">指导意见</td>
            <td colspan="5">{{ fa.BZ }}</td>
          </tr>
          <tr>
            <td class="td-title">处方</td>
            <td colspan="5"> </td>
          </tr>
        </tbody>
      </table>
      <el-tabs v-model="activeName" class="update-solution">
        <el-tab-pane label="相关附件" name="first">
          <p class="pic" v-for="(item, index) in photo" :key="index">
            <i class="el-icon-circle-close" @click="del(item, index)"></i>
            <a
              :href="'http://192.168.11.253:8080/index.php/photo/view?photo=' + item.FileName"
              target="_blank"
            >
              <img
                :src="'http://192.168.11.253:8080/index.php/photo/view?photo=' + item.FileName"
              />
            </a>
          </p>
        </el-tab-pane>
      </el-tabs>
    </main>
    <el-row>
      <el-button @click="editdoc = true">分配医生</el-button>
      <el-button @click="dialogFormVisible = true">修改状态</el-button>
    </el-row>
    <p style="color: #909399;font-size: 14px;">请先审核诊单，在下发给医生。</p>
    <el-dialog title="修改状态" :visible.sync="dialogFormVisible">
      请选择要修改的状态：
      <el-select v-model="changestate" placeholder="请选择状态">
        <el-option v-for="item in options" :key="item.id" :label="item.value" :value="item.id">
        </el-option>
      </el-select>

      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            changestate = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="ModifyState">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改医生" :visible.sync="editdoc">
      请搜索医生：
      <el-input
        placeholder="请输入精确的姓名或电话号"
        clearable
        prefix-icon="el-icon-search"
        v-model="search"
        style="width: 30%;margin-left: 20px;"
      >
      </el-input>
      <el-button type="primary" style="margin-left:20px" @click="query">查询</el-button>

      <el-table v-if="docmsg != ''" style="margin-top:40px;width: 100%" :data="docmsg" border>
        <el-table-column width="80">
          <template slot-scope="scope">
            <el-checkbox v-model="checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="80"></el-table-column>
        <el-table-column prop="hospital" label="所在医院"></el-table-column>
        <el-table-column prop="goodat" label="擅长治疗"></el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editdoc = false">取 消</el-button>
        <el-button type="primary" @click="AssignDoctor">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      KID: "",
      list: "",
      Cust_ID: "",
      State: "",
      activeName: "first",
      custmsg: {},
      Cust_Birthday: "",
      fa: {},
      photo: [],
      uid: "",
      dialogFormVisible: false,
      changestate: "",
      options: [
        { id: 0, value: "未填写" },
        { id: 1, value: "提交" },
        { id: 2, value: "审核" },
        { id: 3, value: "打回" },
        { id: 6, value: "已下发" },
        { id: 9, value: "删除" },
      ],
      editdoc: false,
      search: "",
      docmsg: "",
      checked: true,
      DOCID: "",
      shtype: "",
    };
  },
  mounted() {
    this.KID = this.$route.query.id;
    if (this.$route.query.type) {
      this.shtype = this.$route.query.type;
      this.Cust_ID = sessionStorage.getItem("Cust_IDsh");
    } else {
      this.Cust_ID = sessionStorage.getItem("Cust_ID");
    }

    this.uid = sessionStorage.getItem("id");
    this.getdetail();
    this.getcustdetail();
  },
  methods: {
    getdetail() {
      var url = "";
      if (this.shtype != "") {
        url =
          "/field/get_z_field_detail?kid=" + this.KID + "&input_id=" + sessionStorage.getItem("servicer_id");
      } else {
        url = "/field/get_z_field_detail?kid=" + this.KID;
      }
      this.axios
        .get(url)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.fiels.length > 0) {
              this.list = res.data.fiels;
            } else {
              this.list = res.data.result;
            }
            this.State = res.data.dismain.State;
            if (res.data.fa != "" && res.data.fa != null && res.data.fa != undefined) {
              this.fa = res.data.fa;
            }
            if (res.data.photo != "" && res.data.photo != null && res.data.photo != undefined) {
              this.photo = res.data.photo;
            }
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getcustdetail() {
      this.axios
        .get("/gu/get_cust_info_by_custid?cust_id=" + this.Cust_ID)
        .then((res) => {
          if (res.data.code == 0) {
            this.custmsg = res.data.result;
            this.Cust_Birthday = res.data.result.Cust_Birthday.split(" ")[0];
            this.DOCID = res.data.Cust_Info.Doc_ID;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    del(item, index) {
      this.$confirm("此操作将永久删除该图片, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(
              "/gu/delete_z_field_ftpfile",
              this.qs.stringify({ kid: item.KID, filename: item.FileName })
            )
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    query() {
      this.axios
        .post("/gu/search_gu_docs", this.qs.stringify({ keyword: this.search, is_flag: "all" }))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.docmsg = rs.data.result;
          } else {
            this.$message({
              type: "error",
              message: rs.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AssignDoctor() {
      if (this.checked) {
        // this.axios.post("/gu/update_gu_cust_info_docid", this.qs.stringify({ x_cust_id: this.Cust_ID, doc_id: this.docmsg[0].id, uid: this.uid }))
        this.axios
          .post(
            "/gu/update_z_field_dismain_docid",
            this.qs.stringify({ x_cust_id: this.Cust_ID, doc_id: this.docmsg[0].id, kid: this.KID })
          )
          .then((res) => {
            if (res.data.code == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.editdoc = false;
              this.docmsg = "";
              this.search = "";
              this.reload();
            } else {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message({
          message: "请选择医生",
          type: "info",
        });
      }
    },
    ModifyState() {
      if (this.changestate == 3) {
        this.changestate = 0;
      }
      this.axios
        .post(
          "/gu/update_z_field_dismain_state",
          this.qs.stringify({ state: this.changestate, kid: this.KID, uid: this.uid })
        )
        .then((res) => {
          if (res.data.code == 0) {
            if (this.changestate == 2) {
              this.axios
                .post(
                  "/gu/insert_t_hytl_ta",
                  this.qs.stringify({ x_cust_id: this.Cust_ID, doc_id: this.DOCID, kid: this.KID })
                )
                .then((res) => {
                  console.log(res);
                });
            }
            this.$message({
              message: "修改成功",
              type: "success",
            });
            this.dialogFormVisible = false;
            this.State = this.changestate;
            sessionStorage.setItem("MedicalType", this.changestate);
            this.reload();
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
.el-main {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  overflow: auto;
  padding: 20px;
  width: 100%;
}

#tblA {
  width: 100%;
  margin: auto;
  text-align: left;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #000;
}

table {
  display: table;
  border-collapse: separate;
  white-space: normal;
  line-height: normal;
  font-weight: 400;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border-spacing: 2px;
  border-color: grey;
  font-variant: normal;
}

#tblA td {
  display: table-cell;
  vertical-align: inherit;
  padding: 5px 2px;
}

#tblA td,
#tblB td {
  padding: 5px;
}

.td-title {
  font-weight: 600;
}

.update-solution {
  margin: 30px auto;
  width: 100%;
}

.pic {
  width: 150px;
  height: 150px;
  float: left;
  margin-right: 20px;
  position: relative;
  font-size: 20px;
}

.pic i {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 500;
  cursor: pointer;
}

.pic i:hover {
  color: #409eff;
}

.pic a img {
  width: 100%;
  height: 100%;
}
</style>
